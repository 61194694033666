import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft, faBars, faHome, faAt, faMobileAlt, faPhone, faFax } from "@fortawesome/free-solid-svg-icons";

import "../styles/index.scss";

function toggle_mobile_side_menu() {
  document.getElementById("side-menu").classList.toggle("hidden");
  document.getElementById("side-menu").classList.toggle("flex-1");
  document.getElementById("side-menu-border-mobile").classList.toggle("hidden");
  document.getElementById("content").classList.toggle("hidden");
  document.getElementById("content-container").classList.toggle("flex-1");
}

function init_functions() {
  document.getElementById("side-menu-button-open").onclick = function() {
    toggle_mobile_side_menu();
  };
  document.getElementById("side-menu-button-close").onclick = function() {
    toggle_mobile_side_menu();
  };
  
  let menuButtons = document.getElementsByClassName("menu-button");
  for (let menuButton in menuButtons) {
    menuButton.onclick = function() {
      toggle_mobile_side_menu();
    };
  }
}

document.onload = init_functions;

library.add(faBars, faChevronLeft, faHome, faAt, faMobileAlt, faPhone, faFax);
dom.i2svg();